import { MOBILE_TYPES } from "@/util/mobile.type";
import { DEVICE_TYPES } from "@/util/device.type";
import { mapActions } from "vuex";

export const getParams = {
  data() {
    return {
      platform: this.$route.query.platform,
      device: this.$route.query.device
    };
  },

  computed: {
    isMobile: {
      get: function() {
        return (
          this.platform === MOBILE_TYPES.IOS ||
          this.platform === MOBILE_TYPES.ANDROID
        );
      }
    },

    isPaymentDevice: {
      get: function() {
        return (
          this.platform === MOBILE_TYPES.ANDROID &&
          Object.values(DEVICE_TYPES).includes(this.device)
        );
      }
    }
  },

  methods: {
    ...mapActions(["setIsMobile", "setPlatform", "setDevice"])
  },

  created() {
    this.setIsMobile(this.isMobile);
    this.setPlatform(this.platform);
    this.setDevice(this.device);
  }
};

<template>
  <header>
    <slot name="left-action" v-bind:goBack="goBack" />

    <div>
      <slot name="title" />
    </div>

    <slot name="right-action" />
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  methods: {
    goBack() {
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
header {
  background: $rust-red;
  color: $white;
  height: 56px;
  padding: 0 18px;
  display: flex;
  align-items: center;
  //position: fixed;
  top: 0;
  left: 0;
  right: 0;

  .close,
  .arrow-left {
    display: block;
    height: 24px;
    margin-right: 12px;
    color: $white;
    cursor: pointer;
  }

  .account-circle {
    height: 24px;
    color: $white;
    cursor: pointer;
  }

  a {
    margin-left: auto;
    height: 24px;
    text-transform: uppercase;
    color: $white;
    text-decoration: none;
  }
}
</style>

<template>
  <div>
    <AppHeader v-if="!isMobile">
      <template v-slot:left-action="leftActionProps">
        <arrow-left-icon
          class="arrow-left"
          @click.prevent="leftActionProps.goBack"
        />
      </template>

      <template v-slot:title>
        <h1>{{ $t("account.account") }}</h1>
      </template>
    </AppHeader>

    <main>
      <section>
        <div class="flex-group">
          <account-circle class="account-circle" :size="40" />
          <div v-if="currentUser">
            <h4>{{ currentUser.name }}</h4>
            <p>{{ currentUser.email }}</p>
          </div>
        </div>
        <hr />
        <h4 class="point" v-if="getBranchId">
          {{ $t("account.pickup-point") }}
          <span class="branch-id">{{ getBranchId }}</span>
        </h4>
        <a
          href="#"
          class="btn btn-primary btn-red"
          v-on:click.prevent="logoutFromApp"
        >
          {{ $t("account.logout") }}
        </a>
      </section>
    </main>
    <div class="version text-center">
      <p>Verze: {{ version }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppHeader from "@/components/AppHeader";
import AccountCircle from "vue-material-design-icons/AccountCircle.vue";
import ArrowLeftIcon from "vue-material-design-icons/ArrowLeft.vue";
import Version from "@/util/version";
import { getParams } from "@/mixins/platform-params.js";

export default {
  name: "Account",
  mixins: [getParams],
  components: { AppHeader, AccountCircle, ArrowLeftIcon },
  data() {
    return {
      version: Version
    };
  },

  computed: {
    ...mapGetters("auth", ["currentUser", "getBranchId"])
  },

  methods: {
    ...mapActions("auth", ["logout"]),
    logoutFromApp() {
      this.logout();
      this.$router.replace({
        name: "login",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  max-width: 328px;
  margin: auto;

  .point {
    margin: 17px 0 35px 56px;

    .branch-id {
      color: $orange-red;
    }
  }

  .flex-group {
    display: flex;
    margin: 20px 0 15px;

    .account-circle {
      margin-right: 16px;
      color: $lighter-grey;
    }

    p {
      color: $light-grey;
    }
  }
}

.version {
  color: $dark-grey;
  padding: 50px 0;
}
</style>
